$(document).ready(function () {

    // Breakpoint berechnen
    // Bitte anpassen an _einstellungen.scss
    // https://github.com/jerrylow/breakpoints
    $(window).breakpoints({
        breakpoints: [{
            'name': 'breakpointSmartphoneAb',
            'width': 0
        }, {
            'name': 'breakpointDesktopAb',
            'width': 800
        }]
    });

    // Body je nach Breakpoint anderes data-layout Attribut zuweisen
    var breakpoint;
    $(window).on('inside-breakpointSmartphoneAb', function () {
        $('body').attr('data-layout', 'smartphone');
        breakpoint = 'smartphone';
    });
    $(window).on('inside-breakpointDesktopAb', function () {
        $('body').attr('data-layout', 'desktop');
        breakpoint = 'desktop';
    });
    
    $('#logo').on('click', function (event) {

        if ( $('body').hasClass('body--startseite') && $('body').attr('data-scrolled') == 'false' ) {
            event.preventDefault();
            $('body').attr('data-scrolled', 'true');
        }

    });

    // Body Attribute scrolled = true geben, wenn gescrollt wurde
    var scrollposition = $(window).scrollTop();
    $(window).scroll(function(e) {
        var neueScrollposition = $(window).scrollTop();
        if ( neueScrollposition - scrollposition > 50 ) {
            $('body').attr('data-scrolled', 'true');
        }
    });

    $('.kontaktformular').on('submit', function(event) {

        event.preventDefault();
        
        var angebote = [];
        $('.kontaktformular__angebote input:checked').each(function() {
            angebote.push($(this).val());
        });
        $('#kontaktformular__hidden-angebote').val(angebote.join(', '));
        console.log(angebote.join(', '));

        formular = $(this);
        submit = $(this).find('.button');
        bestaetigung = $(this).find('.kontaktformular__bestaetigung');
        formular.attr('status', 'sendend');
        
        $.ajax({
            method: $(this).attr('method'),
            dataType: "json",
            url: $(this).attr('action'),
            data: formular.serialize(),
            beforeSend: function() {
                formular.find(':input').prop('disabled', true);
            }
        })
        
        .done(function(response) {
            formular.attr('status', 'gesendet');
            submit.hide();
            submit.fadeOut(400, function() {
                bestaetigung.fadeIn();
                $('#kontaktformular__bestaetigung-schliessen').on('click', function() {
                    bestaetigung.fadeOut();
                });
            });
        })
        .fail(function() {
            formular.attr('status', 'fehler');
            formular.find(':input').prop('disabled', false);
        })
        .always(function() {
            
        });
    });

    $('.scrollpfeil').on('click', function() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    });


});