class MenuToggle {
    constructor(hamburger, menue, menuePunkte) {
        this.hamburger = hamburger;
        this.menue = menue;
        this.menuePunkte = menuePunkte;
        this.isActive = false;

        // Add event listener zum hamburger
        this.hamburger.addEventListener('click', this.toggleMenue.bind(this));

        // event listener für Menüpunkte
        this.onMenueItemClick = this.onMenueItemClick.bind(this);
    }
    
    toggleMenue(target) {
        if (this.isActive) {
            this.isActive = false;
            this.menuePunkte.forEach(link => {
                link.removeEventListener('click', this.onMenueItemClick);
            });
            document.body.setAttribute('data-menue-aktiv', this.isActive);
            this.hamburger.style.marginRight = '';
            document.body.style.paddingRight = '';
            document.body.style.top = '';
            window.scrollTo(0, this.y);
            if (target.href) {
                const targetSectionId = event.currentTarget.getAttribute('href'); // Auf event.target zugreifen
                const targetSection = document.querySelector(targetSectionId);
                const targetSectionTop = targetSection.getBoundingClientRect().top + window.scrollY;
                const targetPosition = targetSectionTop;

                window.scrollTo({
                    top: targetPosition,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        } else {
            this.isActive = true;
            // document.body.addEventListener('click', this.onBodyClick.bind(this));
            this.menuePunkte.forEach((link, index) => {
                link.addEventListener('click', this.onMenueItemClick);
            });
            this.scrollbar = window.innerWidth - document.documentElement.clientWidth;
            this.y = window.scrollY;
            document.body.setAttribute('data-menue-aktiv', this.isActive);
            const marginOriginal = parseFloat(window.getComputedStyle(this.hamburger).marginRight);
            this.hamburger.style.marginRight = `${marginOriginal + this.scrollbar}px`;
            document.body.style.paddingRight = `${this.scrollbar}px`;
            document.body.style.top = `-${this.y}px`;
        }
    }

    onMenueItemClick(event) {
        const target = event.target;
        if (target.matches('.menue__link')) {
            if (document.body.getAttribute('data-site') != 'andreasFleischlin') {
                this.toggleMenue(target);
            } else {
                this.toggleMenue();
            }
        }
    }
}
