class WeichesScrollenZu {
    constructor(linkKlasse) {
        this.links = document.querySelectorAll(linkKlasse);
        this.scrollToSection = this.ScrollFunktion.bind(this);
        this.links.forEach(button => {
            button.addEventListener('click', this.scrollToSection);
        });
    }

    ScrollFunktion(event) {
        event.preventDefault();

        const targetSectionId = event.currentTarget.getAttribute('href'); // Auf event.target zugreifen
        const targetSection = document.querySelector(targetSectionId);
        const targetSectionTop = targetSection.getBoundingClientRect().top + window.scrollY;
        const targetPosition = targetSectionTop;

        window.scrollTo({
            top: targetPosition,
            left: 0,
            behavior: 'smooth'
        });
    }
}